import React, { Component } from "react"
import { Helmet } from "react-helmet"
import videos from "../assets/videos"
import images from "../assets/images"
import TopMenu from "../components/TopMenu"
import BottomMenu from "../components/BottomMenu"
class Story extends Component {
  render() {
    return (
      <>
        <TopMenu />
        <main className="story">
          <Helmet
            title={
              "Storia Lericas - Storia Erica Scoparia - Costruzione Artigianale"
            }
          >
            <meta
              name="description"
              content="Storia Lericas - Un' azienda nata dalla bosco che offre le mogliori soluzioni artigianali"
            />
          </Helmet>
          <video
            width="100%"
            height="100%"
            autoPlay
            preload="true"
            style={{ objectFit: "contain" }}
          >
            <source src={videos.story} type="video/mp4" />
          </video>
          <section className="founder">
            <h2>Luigi Strazza</h2>
            <h4>Fondatore</h4>
            <img src={images["luigi"]} alt="founder" />
          </section>
          <section className="description">
            <h1>La nostra storia</h1>
            <span>
              La ditta Lericas di Strazza Luigi è situata a San Lorenzo a Merse,
              un piccolo borgo medievale a pochi chilometri da Siena. Con
              l'utilizzo dei prodotti dei nostri boschi realizziamo
              artigianalmente coperture di vario genere, strutture in legno come
              pergolati, parcheggi auto, verande, gazebo, ombrelloni, pannelli
              frangisole/frangivento, panchine, staccionate e quant'altro.
              <br />
              Siamo inoltre specializzati nella lavorazione di erica scoparia
              per la realizzazione di scope e rivestimenti in ambito turistico e
              privato. Commercializziamo casette di legno di varie dimensioni e
              tipologie, articoli per l'arredo giardino , stuoie di vari tipi (
              Bambù-Canna Cinese-Erica-ecc.), scope di bambù, saggina e
              plastica. Siamo a disposizione per soddisfare ogni vostra
              richiesta e siamo ben lieti di consigliarvi la migliore soluzione,
              fornendovi preventivi su misura e gratuiti.
            </span>
          </section>
        </main>
        <BottomMenu />
      </>
    )
  }
}

export default Story
